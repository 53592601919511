<template>
  <v-tooltip
    v-bind="$attrs"
    :content-class="[
      'cy-tooltip',
      `cy-tooltip--theme-${theme}`,
      activeAlignment ? `cy-tooltip--align-${activeAlignment}` : '',
      $scopedSlots.actions ? 'cy-tooltip--clickable' : '',
      $attrs['content-class'],
    ].join(' ')"
    :transition="$attrs.transition || {
      top: 'scroll-y-reverse-transition',
      right: 'scroll-x-transition',
      bottom: 'slide-y-transition',
      left: 'scroll-x-reverse-transition',
    }[activeAlignment]">
    <template #activator="{ on, attrs }">
      <slot
        v-bind="{ on, attrs }"
        name="activator"/>
    </template>
    <v-row
      justify="space-between"
      align="center"
      dense>
      <v-col
        v-if="icon"
        cols="auto">
        <v-icon
          small
          dense>
          {{ icon }}
        </v-icon>
      </v-col>
      <v-col>
        <slot/>
      </v-col>
      <v-col
        v-if="!!$scopedSlots.actions"
        :cols="inlineButton ? 'auto' : 12"
        :class="[{ 'text-right': !inlineButton }]">
        <slot name="actions"/>
      </v-col>
    </v-row>
  </v-tooltip>
</template>

<script>
export const themes = ['primary', 'secondary', 'success', 'warning', 'error']
export const alignments = ['top', 'right', 'bottom', 'left']

export default {
  name: 'CyTooltip',
  props: {
    theme: {
      type: String,
      validator: (theme) => themes.includes(theme),
      default: 'primary',
    },
    icon: {
      type: String,
      default: '',
    },
    inlineButton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    activeAlignment () {
      const alignmentAttrs = _.pick(this.$attrs, alignments)
      const allowedValues = [true, '']
      return _.findKey(alignmentAttrs, (value) => allowedValues.includes(value))
    },
  },
  mounted () {
    const hasColorAttr = _.has(this.$attrs, 'color')
    if (hasColorAttr) {
      const { theme, $attrs: { color } } = this
      console.warn(`[CyTooltip] "color" prop found, please remove this. Use "theme" instead.`, { color, theme, parent: this.$parent.$options.name })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "styles/variables/cytooltip";

.cy-tooltip {
  font-size: $body-font-size;

  .v-icon {
    font-size: $icon-font-size !important; // Vuetify override
  }

  // Themes
  @each $theme, $config in deep-map-get($themes) {
    &--theme-#{$theme} {
      background-color: map.get($config, "background");
      color: map.get($config, "color");

      &::before {
        border-color: map.get($config, "background");
      }

      .v-icon {
        color: map.get($config, "icon-color");
      }

      ::v-deep .v-btn {
        background-color: map.get($config, "button-background");
        color: map.get($config, "button-color");
      }
    }
  }

  &--clickable {
    pointer-events: auto;
  }

  // Arrows
  &::before {
    content: "";
    position: absolute;
    z-index: -21;
    width: 0;
    height: 0;
    border-width: #{$arrow-size}px;
    border-style: solid;
  }

  &--align-top::before {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  &--align-bottom::before {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
  }

  &--align-left::before {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }

  &--align-right::before {
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}
</style>
